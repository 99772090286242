@mixin iconStyle() {
  @apply w-full h-9 rounded-50;
  background-color: #383838;
  background-size: 100% 100%;
}

.floatBox {
  @apply w-9 fixed right-2 h-auto;
  bottom: 190px;
  z-index: var(--pop-index);

  .floatBoxTv {
    @apply cursor-pointer relative;
    @include iconStyle();
    background-image: url('/api/images/float_tv');

    &:hover {
      background-image: url('/api/images/float_tv_active');

      .floatBoxTvCode {
        @apply flex;
      }
    }

    .floatBoxTvCode {
      @apply absolute right-14 top-2/4 rounded-lg hidden flex-col items-center bg-bg25;
      transform: translateY(-50%);
      width: 180px;
      height: 192px;
      padding: 16px 15px;

      .code-text {
        @apply text-sm text-white text-center;
      }

      .code-box {
        @apply w-28 h-28 mt-2;
        background-color: #313131;

        .code-box-img {
          @apply w-24 h-24 ml-2 mt-2;
        }
      }
    }
  }

  .up {
    @apply mt-2 cursor-pointer;
    @include iconStyle();
    background-image: url('/api/images/float_up');

    &:hover {
      background-image: url('/api/images/float_up_active');
    }
  }
}
